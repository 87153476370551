.admin {
    .hidden {
        display: none;
    }
    .helper {
            display: flex;
            text-align: left;
            justify-self: start;
            justify-content: flex-start;
            
        }
    height: 100vh;
    width: 99%;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    .breadcrumb {
        margin-top: 0;
        margin-bottom: 3rem;
    }
    .is-active {
        font-weight: bold;
    }
    .adminTable {
        margin-top: 23px;
    }
    .form {
        margin-top: 30px;
    }
    button {
        margin: 1px;
    }
    .buttons {
        margin-top: 10px;
    }

    p {
        width: 100%;
        height: 2.5rem;
    }
    hr {
        margin-bottom: 50px;
    }

    .inputSection {
        margin: 0px 5px 30px 5px;
    }
    .smallSection {
        display: flex;
        flex-direction: row;
        margin: 0px 5px 30px 5px;
    }
    .checkboxWrapper {
        margin: 0px 5px 30px 5px;
    }

    .help {
        height: fit-content;
    }

.newDashboard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.readOnly {
    height: fit-content;
    max-height: 100px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        display: none;
      }
}
.hidden {
    display: none;
}
.input{
    &.number{
        width: fit-content;
    }
}
#default_dashboard .e-panel { 
    transition:none !important; 
  } 

  .react-grid-layout {
    transition: none !important;
  }
  .react-grid-item {
    transition-property: none !important;
  }

  .pagination {
      margin-bottom: 5px;
  }


  .is-horizontal{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      &.label{
          justify-content: flex-start;
      }
      &.field-body{
        justify-content: flex-start;
    }
  }
  .check {
      margin-bottom: 10px;
  }

  .text {
        line-height: 250%;
  }

// 
}
