// package files
@import '~bulma/bulma.sass';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-grid-layout/css/styles.css';
@import '~ol/ol.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~@fortawesome/fontawesome-free/css/all.css';

// project files
@import 'styles/variables.scss';
@import 'styles/elem.scss';
@import 'styles/app.scss';
@import 'styles/navigation.scss';
@import 'styles/widgets.scss';
@import 'styles/landing-page.scss';
@import 'styles/admin.scss';
@import 'styles/error.scss';


html {
    overflow: hidden;
}

.pageContent {
    height: 100vh;
    width: 100%;
    @include until($tablet) {
        aside {
        display: none !important
        }
    }
}