// tooltip
.tooltipHover {
    opacity: 1 !important;
    pointer-events: auto !important;
    max-width: 550px;
    cursor: default;
    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
        @include mobile {
            visibility: hidden !important;
        }
    }
    }


.__react_component_tooltip {
    background-color: $primary;
    &::after {
        border-top-color: $primary;
    }
}

.__react_component_tooltip.show {
    @include mobile {
        visibility: hidden !important;
    }
}

.iconTooltip {
    background: $link !important;
    font-weight: lighter;
    font-size: 11px;
    padding: 5px 10px;
    &::after {
        border-right-color: $link !important;
    }
}

// topBar
.topBarWrapper {
    z-index: $navbar-z-index;
    display: flex;
    position: absolute;
    left: var(--navbar-width);
    width: calc(100% - var(--navbar-width));
    justify-content: space-between;
    align-items: center;
    height: $topbar-height;
    background-color: $topbar-background-color;
    padding: 0px var(--content-breathing-room);

    .widgetGroupNameWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        @include until($tablet) {
            letter-spacing: normal;
            font-size: small;
        }
        .widgetGroupName {
            display: flex;
            letter-spacing: 0.7px;
            font-size: large;
            font-weight: bold;
            align-items: center;
            @include until($tablet) {
                letter-spacing: normal;
                font-size: small;
            }
            .dashboardName{
                margin-right: 5px;
            }
            .pageName {
                @include until($tablet) {
                    display: none;
                }
            }
            .mobileName {
                @include from($tablet) {
                    display: none;
                }
                
            }
            .dropdown-item {
                font-weight: normal;
                &.active {
                    background-color: $navbar-active-icon-background-color !important;
                    color: $navbar-active-icon-color !important;
                }
            }
        }
    }
}

.topBarControls {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    padding: 0px var(--content-breathing-room);
    height: 100%;

}

.editLayoutToggleWrapper {
    background-color: $white;
}

.toggle {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px $topbar-toggle-padding;
    cursor: pointer;
}

.topBarDateComponentWrapper, .topBarEditComponentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}

.topBarEditComponentWrapper {
    transition: width .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
    border-radius: 6px;
    @extend .has-text-white;
    @extend .has-background-grey;
}

.resetToggle {
    border-left: solid white 1px;
    border-right: solid white 1px;
}

.topBarDateWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px $topbar-toggle-padding;
    &.presetRange {
        padding-left: 0px;
    }
    .label {
        padding: 0px $topbar-toggle-padding;
    }
    @include mobile {
        display: none;
    }
}

// loading spinner
.loadingSpinnerWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

// react-grid-layout
.react-draggable-dragging {
    z-index: 2;
}

.react-grid-layout {
    z-index: 1;
}



// date selector
//// react datepicker overrides
.react-datepicker-wrapper {
    display: flex;
    @media screen and (max-width: 460px) {
        width: 90px;
    }
}
.react-datepicker-popper {
    z-index: 5;
}

.react-datepicker {
    font-family: unset;
    font-size: unset;
}

.dateRangeName {
    width: fit-content;
}
.dateRangeString {
    @include until($tablet){
        display: none;
    }
}

.react-datepicker__month {
    margin: 0
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    @include until($desktop) {
        line-height: normal;
        width: 1.4rem !important;
    }
}

.react-datepicker__current-month {
    display: none;
}

.react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll {
    padding: 0px;
    border-radius: 4px;
    display: flex;
    flex-basis: 30%;
    @extend .button;
    @extend .is-small;
    font-weight: bold;
    font-size: 0.9em;
}

.react-datepicker__month-read-view, .react-datepicker__year-read-view {
    visibility: visible !important;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    display: none;
}

.react-datepicker__month-option, .react-datepicker__month-year-option {
    padding: 2px 10px;
}

.react-datepicker__year-option {
    padding: 5px 15px;
    font-size: 0.8em;
}

.react-datepicker__header__dropdown, .react-datepicker__header__dropdown--scroll {
    display: flex;
    justify-content: center;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
    width: unset;
    left: unset;
    background-color: $white;
    font-weight: 400;
}

.react-datepicker__navigation {
    top: 20px;
}

.react-datepicker__navigation--previous {
    border-right-color: $dark;
    &:hover {
        border-right-color: $dark;
    }
}
.react-datepicker__navigation--next {
    border-left-color: $dark;
    &:hover {
        border-left-color: $dark;
    }
}

.react-datepicker__header {
    background-color: $white;
}
.react-datepicker__day-name {
    font-weight: bold;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
    display: none;
}

.react-datepicker__month-dropdown, .react-datepicker__year-dropdown--scrollable {
    height: 200px;
    overflow-y: scroll;
}

// date selector components
.dateDrawerWrapper {
    width: calc(100% - var(--navbar-width));
    position: relative;
    overflow: hidden;
    top: $topbar-height;
    left: var(--navbar-width);
}

.dateDrawer {
    min-height: fit-content;
    max-height: 1000px;
    z-index: 3;
    background-color: $date-drawer-background-color;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: center;
    align-items: flex-start;
    border-bottom: solid 1px $topbar-border-color;
    top: 0;
    left: 0;
    transition: max-height .25s cubic-bezier(0.820, 0.085, 0.395, 0.895);
    @include mobile {
        flex-wrap: wrap;
        min-height: 275px;
    }
    &.inactive {
        transition: max-height 0.25s ease-out;
        max-height: 0;
        min-height: 0;
        border-bottom: none;
    }

}

.dateSelectorWrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 1vw 0px 0px;
}

.dateSelectorLabelWrapper {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 5px;
    font-weight: bold;
}

.dateControlsWrapper {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;


    .dateOptionsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding-left: 3px;

    }

    .dateOptions {
        display: flex;
        flex-direction: column;
        width: $date-option-width;
        flex: none;
        
        .dateOptionWrapper {
            padding: 5px 0px 5px 0px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            &.active {
                font-weight: bold;
            }
            .dateOption {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }   
        @include until($desktop) {
            line-height: 1.0;
            font-size: .75em;
            padding: 0.5rem;
        }

    }
    .dateSelectButton {
        right: 187%;
        bottom: 30%;
        
        @media screen and (max-width: 460px) {
            right: 30%;
            bottom: 0%;
        }
        @include from($tablet) {
            right: 14%;
            bottom: 7%;
        }
    }
}

.internalLink {
    cursor: pointer;
}

// maximize / minimize button
.maximizeButton {
    display: flex;
    padding: 0px 5px;
    cursor: pointer;   
}

.exportButton {
    display: flex;
    padding: 0px 10px;
    cursor: pointer; 
}

.button.no-margin {
    margin: 0px;
}
.select.is-full-height {
    height: 100%;
    cursor: pointer;
    .reactSelect__control {
        height: 100%;
    }
}

.reactSelect__single-value {
    padding-left: 5px;
}

.reactSelect__input {
    padding-left: 5px;
}